import React, { useState, useEffect, } from 'react';
import DatePicker from 'react-date-picker';
import { GetCustomers } from '../../api/customer-api';
import { GetInvoice, GetInvoiceAll } from '../../api/reports-api';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

const Report = (props) => {
    let date = new Date();
    const [byDate, setByDate] = useState(false);
    const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    const [reportCustomer, setReportCustomer] = useState(-1);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        loadCustomers();
    }, []);

    const loadCustomers = () => {
        GetCustomers(true)
            .then((result) => {
                if (result.success) {
                    setCustomers(result.customers);
                    if (result.customers.length > 0) {
                        setReportCustomer(0); // Default first item (all customers report)
                    }
                }
            })
            .catch(() => {
            });
    }

    const getReport = () => {
        let download = require("downloadjs");
        let sdate = startDate;
        let edate = endDate;

        if (!byDate) {
            edate = new Date(sdate.getFullYear(), sdate.getMonth() + 1, 0)
        }

        let sdateMonth = sdate.getMonth() + 1;
        let edateMonth = edate.getMonth() + 1;

        if (Number(reportCustomer) === 0) {
            GetInvoiceAll(Number(reportCustomer), sdate, edate)
                .then((result) => {
                    if (result != null) {
                        result.blob().then(function (myBlob) {
                            console.log(myBlob);

                            // for custom date range
                            if (byDate === true) {
                                download(
                                    myBlob,
                                    "DTI_Invoice_All_" + sdateMonth + sdate.getFullYear() + "_" + edateMonth + edate.getFullYear() + ".xlsx",
                                    myBlob.type
                                );
                            }
                            // for month
                            else {
                                download(
                                    myBlob,
                                    "DTI_Invoice_All_" + sdateMonth + sdate.getFullYear() + ".xlsx",
                                    myBlob.type
                                );
                            }
                        });
                    } else {
                    }
                })
                .catch((e) => {
                    console.log("Invoice All - Download failed.");
                });
        }
        else {
            let customer = customers.find((c) => c.customerID === Number(reportCustomer));
            GetInvoice(Number(reportCustomer), sdate, edate)
                .then((result) => {
                    if (result != null) {
                        result.blob().then(function (myBlob) {
                            console.log(myBlob);
                            var fileName = "";
                            // for custom date range
                            if (byDate === true) {

                                fileName = "DTI_Invoice";
                                if (customer) {
                                    fileName = fileName + "_" + customer?.customerNumber;
                                }
                                fileName = fileName + "_" + sdateMonth + sdate.getFullYear() + "_" + edateMonth + edate.getFullYear() + ".xlsx";
                                download(
                                    myBlob,
                                    fileName,
                                    myBlob.type
                                );
                            }
                            // for month
                            else {
                                fileName = "DTI_Invoice";
                                if (customer) {
                                    fileName = fileName + "_" + customer?.customerNumber;
                                }
                                fileName = fileName + "_" + sdateMonth + sdate.getFullYear() + ".xlsx";
                                download(
                                    myBlob,
                                    fileName,
                                    myBlob.type
                                );
                            }
                        });
                    } else {
                    }
                })
                .catch(() => {
                    console.log("Customer Invoice - Download failed.");
                });
        }
    }

    return (
        <div className='container'>
            <div className='pt-2 row'>
                <div className='col-lg'>
                    <h1>Reports</h1>
                </div>
              
            </div>
            <Form className='dt-rem-3' onSubmit={(e) => { e.preventDefault(); getReport(); }}>
                <Row className="mb-3">
                    {props.userType === "employee" ?
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Customer</Form.Label>
                            <Form.Select name="role" value={reportCustomer} onChange={(e) => setReportCustomer(e.target.value)} >
                                <option value={Number(0)}>All Customer Report</option>
                                {customers.map((c) =>
                                    <option key={c.customerID} value={c.customerID} >{c.name}</option>
                                )}
                            </Form.Select>
                        </Form.Group>
                        :null
                        }
                    <Form.Group as={Col} controlId="formGridFName" className="align-bottom col-sm-auto">
                        <Form.Label>{!byDate ? "Month" : "From"}:</Form.Label>
                        <div>
                            <DatePicker 
                                value={startDate} onChange={date => setStartDate(date)} clearIcon={null} maxDetail={byDate ? "month" : "year"} />
                        </div>
                    </Form.Group>
                    {byDate &&
                        <Form.Group as={Col} controlId="formGridFName" className="align-bottom col-sm-auto">
                            <Form.Label>To:</Form.Label>
                            <div>
                                <DatePicker
                                    value={endDate} onChange={date => setEndDate(date)} clearIcon={null} maxDetail={byDate ? "month" : "year"} />
                            </div>
                        </Form.Group>
                    }
                    <Form.Group as={Col} controlId="formGridFName" className="align-bottom col-sm-auto">
                        <Form.Switch
                            className='dt-rem-3'
                            label='Date Range'
                            checked={byDate}
                            name='active'
                            onChange={() => setByDate(!byDate)}
                        />
                    </Form.Group>
                </Row>
                <Row className="pt-2 row">
                    <Form.Group as={Col} controlId="formGridFName">
                        <Button className="deaftalk-button" type="submit"> Submit </Button>
                    </Form.Group>
                </Row>
            </Form>
        </div>
    );
};
export default Report;
